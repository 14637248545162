import React from 'react'
import { Box, Container, InputAdornment } from '@mui/material'
import { Email } from '@mui/icons-material'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import { Auth } from '@aws-amplify/auth'

import { useAccount } from 'account/context/AccountContext'
import { useLoading } from '_core/context/LoadingContext'
import { useMessage } from '_core/context/MessageContext'
import AWSConfig from 'account/constants/aws-config'

import AuthPageLayout from 'account/components/organisms/global/AuthPageLayout'
import Flex from '_core/components/atoms/layout/Flex'
import FormikTextField from '_core/components/atoms/inputs/formik/FormikTextField'
import Button from '_core/components/atoms/buttons/Button'

type formValues = {
  email: string
}

Auth.configure(AWSConfig)

const ResetPassword = () => {
  const { dispatch: loadingDispatch } = useLoading()
  const { dispatch: messageDispatch } = useMessage()

  const initialValues = {
    email: '',
  }
  const validationSchema = object().shape({
    email: string()
      .email('Please supply a valid email address')
      .required('Email address is Required'),
  })

  const handleSubmit = async (values: formValues) => {
    loadingDispatch({ type: 'START_LOADING' })
    let statusObj
    try {
      await Auth.forgotPassword(values.email)

      messageDispatch({
        type: 'SET_MESSAGE',
        payload: {
          message:
            'An email has been sent to your account with further instructions.',
          status: 'success',
        },
      })

      loadingDispatch({ type: 'STOP_LOADING' })
      return statusObj
    } catch (err) {
      if (err.code === 'InvalidParameterException') {
        messageDispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: 'An account with that email address does not exist.',
            status: 'error',
          },
        })
      } else {
        messageDispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: err.message,
            status: 'success',
          },
        })
      }
      loadingDispatch({ type: 'STOP_LOADING' })
      return statusObj
    }
  }

  return (
    <>
      <AuthPageLayout>
        <Flex center textAlign="center" flexGrow="1">
          <Container maxWidth="sm">
            <Box component="h1" typography="h2" mb={1}>
              Reset your password
            </Box>
            <Box component="p" mb="3" color="textGrey">
              Enter your email address to reset your password
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values)
              }}
            >
              <Form>
                <FormikTextField
                  name="email"
                  label="Email Address"
                  placeholder="Email Address"
                  type="email"
                  withAdornment
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email sx={{ fontSize: '1.8rem' }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  caps
                  required
                  sx={{ mb: 3 }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  sizeVariant="xl"
                  type="submit"
                >
                  Reset
                </Button>
              </Form>
            </Formik>
          </Container>
        </Flex>
      </AuthPageLayout>
    </>
  )
}

export default ResetPassword
